import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
// import Banner from "../Components/Banner";
import "./Offerings.css";
import data from "../../data.json";
import { Modal, Button, Carousel, Container } from "react-bootstrap";
import UploadOfferings from "../Components/UploadOfferings";

const ModalOverlay = ({ show, hide }) => {
  const content = (
    <>
      <Modal
        show={show}
        onHide={hide}
      >
        <Modal.Header
          className="border-0"
          closeButton
        ></Modal.Header>

        <Modal.Body className="border-0 text-center">
          <p className="text-center">
            <i className="bi bi-info-circle display-1 text-info"></i>
          </p>
          <h4 className="pb-4 txt-blue">Please go through the section</h4>
          <h4 className="txt-orange">
            <a
              href="#how-to"
              onClick={hide}
            >
              How to Write Vyasa Puja Offering
            </a>
          </h4>
          <h4 className="txt-blue">for writing tips</h4>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            className="mx-auto"
            variant="primary"
            onClick={hide}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  return ReactDom.createPortal(
    content,
    document.getElementById("modal-content")
  );
};

const Offerings = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    setShowModal(false);
  }, []);
  return (
    <>
      {/* <Banner txt="Upload Offerings" /> */}
      {showModal && (
        <ModalOverlay
          show={showModal}
          hide={handleClose}
        />
      )}
      <Container
        fluid
        className="form-container"
      >
        <h3 className="text-center pt-5 pb-5 txt-blue">Upload your Offering</h3>
        <Container className="pb-5">
          <p
            style={{ background: "#fff" }}
            className="text-center p-5 shadow-lg"
          >
            For better insights on how to write the perfect offering please go
            through <a href="#how-to">writing tips for offering</a> section. You
            can even{" "}
            <a
              href="https://gkgvyasapuja.blob.core.windows.net/offerings/Sample%20Offering%20English%20And%20Hindi.pdf"
              target="_blank"
              rel="noreferrer"
            >
              download sample offering
            </a>{" "}
            file for reference.
          </p>
          <UploadOfferings />
        </Container>
      </Container>
      <Container
        className="img-bg mx-0 px-0 py-5"
        fluid
      >
        <Container className="vyaspuja-wrapper text-center">
          <>
            <h2 className="txt-blue vyaspuja-header">
              Importance of Vyasa-puja for a Disciple
            </h2>
            <p className="py-3">
              The appearance day of our dear Gurudeva, His Holiness Gopal
              Krishna Goswami Maharaja falls on the most auspicious day of
              Annada Ekadashi.It is a very special day not only for his
              disciples but for the entire ISKCON society, because he has won
              everyone's hearts by his compassionate nature.
            </p>
            <p className="py-3">
              We are eternally indebted to Guru Maharaja for showing us the path
              of devotion and as a small gesture of love towards him, we attempt
              to write the Vyasa Puja offering and glorify him. Every year,
              disciples from all over the world mail these offerings in advance
              ,then they are compiled into a book called the Vyasa Puja book of
              the year.
            </p>
            <p className="py-3">
              On the auspicious day of Guru Maharaja's appearance, this book is
              presented to Gurudeva, who opens the book and reads the offerings.
              This is the moment which the disciples are anxiously waiting for,
              hoping that Guru Maharaja reads their offering. So in short, our
              love for our Guru is expressed through our Vyasa Puja offerings.
              Each of the offerings written by disciples comes straight from
              their heart, there is no doubt about it, but sharing some of the
              tips to make our offerings exceptionally good
            </p>
          </>
        </Container>
      </Container>
      <Container
        fluid
        className="vyaspuja-container"
        id="how-to"
      >
        <Container className="text-center">
          <>
            <h2 className="txt-blue py-5">How to Write Vyasa Puja Offering</h2>
            <h4 className="txt-blue pb-3">Addressing</h4>
            <p className="pb-3">
              This is the main part where we address Guru Maharaja, so we can
              start with Dear Guru Maharaja, Guru deva, etc. followed by "Please
              accept my humble obeisances at the dust of your lotus feet".
            </p>
            <h4 className="txt-blue pb-3">Main Offering</h4>
            <p>
              Here the disciple can quote the guru pranam mantra or any shloka
              glorifying Gurudeva along with its reference. For example:
            </p>
            <article className="">
              <p className="mb-0">
                <span className="pe-1">&#10077;</span>titiksavah karunikah,
                suhrdah sarva-dehinam
              </p>
              <p className="mb-0">
                ajata-satravah santah, sadhavah sadhu-bhusanah
                <span className="ps-1">&#10078;</span>
              </p>
            </article>
            <p className="p-5 m-3">
              The symptoms of a sadhu are that he is tolerant, merciful and
              friendly to all living entities. He has no enemies. He is
              peaceful, he abides by the scriptures, and all his characteristics
              are sublime. (SB CANTO 3 CHAP 25 TEXT 21)
            </p>
            <ul className="text-start poster_color text-white p-5">
              <li className="pb-3">
                We can then relate the quote to our Guru Maharaja and give
                examples on how he is living up to the quote. Senior disciples,
                who have personally assisted Gurudeva can share their personal
                interactions with Guru Maharaja, those who are initiated and are
                waiting to get personal association of Gurudeva, can share the
                incidents heard from lectures or their personal thoughts, those
                who are yet to be initiated can read the previous years Vyasa
                puja offerings made by senior disciples from the Vyasa puja book
                and gain in depth knowledge of Guru Maharaja's glories and write
                their offerings.
              </li>
              <li className="pb-3">
                Next we can emphasize the role of Guru Maharaja in our lives,
                how it has changed us.
              </li>
              <li className="pb-3">
                Lastly we can write what have we contributed as a disciple
                towards our Guru Maharaja . It can be collecting funds for the
                temple, conducting preaching programs at homes, colleges, public
                events etc. and the thing that pleases Guru Maharaja the most,
                the count of Srila Prabhupada's books we have distributed this
                year, in book marathon as well as the entire year. Let us write
                this section as an offering to Gurudeva and not as a means to
                glorify ourselves, so a personal request to everyone to keep it
                short and sweet.
              </li>
            </ul>
            <h2 className="txt-blue pt-5 pb-3">Ending</h2>
            <p className="pb-5">
              We can complete the offering by "Praying to Their Lordships (name
              of deity) to bless Guru Maharaja with good health and long life"
              followed by quotes like "Please keep us under the shelter of your
              lotus feet". Or " Please keep showering your mercy on us" etc. In
              the end complete the offering with Yours' insignificant or humble
              children/ disciple/ servant. The write your name and the ISKCON
              temple you are connected with.
            </p>
          </>
        </Container>
      </Container>
      <Container
        fluid
        className="py-5 my-5"
      >
        <Carousel
          variant="dark"
          indicators={false}
          interval={null}
          className="carousel-wrapper"
        >
          {data.offering_carousel.map((item, i) => (
            <Carousel.Item key={`item-${i}`}>
              <div className="modified shadowing">
                <p className="">"{item}"</p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </>
  );
};

export default Offerings;
