import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./index.css";
import App from "./App";
import Footer from "./user/Components/Footer";
import Header from "./user/Components/Header";
import Offerings from "./user/Pages/Offerings";
import Donate from "./user/Pages/Donate";
import About from "./user/Pages/About";
import EBooks from "./user/Pages/EBooks";
import PrivacyPolicy from "./user/Pages/PrivacyPolicy";
import ScrollToTop from "./user/Components/ScrollToTop";
import ContactUs from "./user/Pages/ContactUs";
import Temp from "./user/Components/Temp";
import FormStatus from "./user/Components/FormStatus";
import BackToTopButton from "./user/Components/BackToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="cover-content">
        <Header />
        <ScrollToTop>
          <Routes>
            <Route
              exact
              path="/"
              element={<App />}
            />
            <Route
              exact
              path="/our-offerings"
              element={<Offerings />}
            />
            <Route
              exact
              path="/donate"
              element={<Donate />}
            />
            <Route
              exact
              path="/about-us"
              element={<About />}
            />
            <Route
              exact
              path="/our-ebooks"
              element={<EBooks />}
            />
            <Route
              exact
              path="/contact-us"
              element={<ContactUs />}
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path="/temp"
              element={<Temp />}
            />
            <Route
              exact
              path="/form"
              element={<FormStatus />}
            />
          </Routes>
        </ScrollToTop>
        <BackToTopButton />
      </div>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
