import React, { useEffect, useState } from "react";
import "./EBooks.css";
import Banner from "../Components/Banner";
import { Row, Container } from "react-bootstrap";
import Card from "../Components/Card";
import { makeApiCallFor } from "../../api";

const EBooks = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetchBooks();
    async function fetchBooks() {
      const { data } = await makeApiCallFor("getBooks", "");
      const values = Object.entries(
        data.reduce((acc, item) => {
          if (!acc[item.year]) {
            acc[item.year] = [];
          }
          acc[item.year].push(item);
          return acc;
        }, {})
      );
      let collections = values
        .map(([year, data]) => ({ year, data }))
        .sort((a, b) => b.year - a.year);
      setBooks(collections);
    }
  }, []);
  return (
    <>
      {/* <Banner txt="E Books" /> */}
      <Container className="pb-5">
        <section className="py-5 my-5 text-center display-6 txt-blue ebooks-section">
          Vyasa Puja E-Books
        </section>
        {books.map((book, i) => (
          <>
            <div
              key={i}
              id={i}
            >
              {book.data.some((bk) => bk.status === 1) && (
                <p className="py-1 text-center ebooks-years">{book.year}</p>
              )}
              <Row>
                <Card year={book.data} />
              </Row>
            </div>
          </>
        ))}
      </Container>
    </>
  );
};

export default EBooks;
