import "./App.css";
import {
  Carousel,
  Col,
  Row,
  Container,
  Button,
  Image,
  Card,
  Nav,
} from "react-bootstrap";
import bg1 from "./assets/images/home_bg1.jpg";
import bg2 from "./assets/images/home_bg2.jpg";
import bg3 from "./assets/images/home_bg3.jpg";
import img1 from "./assets/images/01.jpg";
import img2 from "./assets/images/06.jpg";
import img3 from "./assets/images/02.jpeg";
import img4 from "./assets/images/03.jpg";
import img5 from "./assets/images/07.jpeg";
import img6 from "./assets/images/04.jpeg";
import img7 from "./assets/images/05.jpg";
import img8 from "./assets/images/08.jpg";
import pic from "./assets/images/prabhupada.jpg";
import quote from "./assets/images/quote.png";
import sermon1 from "./assets/images/sermon1.jpg";
import sermon2 from "./assets/images/sermon2.jpg";
import sermon3 from "./assets/images/sermon3.jpg";

function App() {
  const images = [
    {path: bg1, alt: "img1", id: "01"},
    {path: bg2, alt: "img2", id: "02"},
    {path: bg3, alt: "img3", id: "03"},
  ];

  const gallery = [
    {path: img1, alt: "gallery1", id: "01"},
    {path: img2, alt: "gallery2", id: "02"},
    {path: img3, alt: "gallery3", id: "03"},
    {path: img4, alt: "gallery4", id: "04"},
    {path: img5, alt: "gallery5", id: "05"},
    {path: img6, alt: "gallery6", id: "06"},
    {path: img7, alt: "gallery7", id: "07"},
    {path: img8, alt: "gallery8", id: "08"},
  ];

  const quotes = [
    "A spiritual master who is one hundred percent Krishna conscious is the bona ﬁde spiritual master. Bhagavad Gita 2.8",
    "If we take shelter of the lotus feet of the spiritual master, we can become free from illusion, fear, and distress. If we wholeheartedly beg for his mercy without any deceit then the spiritual master bestows all auspiciousness upon us.",
    "The spiritual master is the mercy representative of the Lord. Srimad Bhagavatam 1.7.22",
  ];

  const sermons = [
    {
      path: sermon1,
      alt: "image1",
      date: "Febraury 25",
      txt: "Krishna Consciousness movement is the highway that will take you to Krishna but on the sides there are many side streets, so don't get distracted.",
      id: "01",
    },
    {
      path: sermon2,
      alt: "image2",
      date: "June 06",
      txt: "If one is situated in real krishna consciousness, he can face the most severe type of adversity and remain completely undisturbed.",
      id: "02",
    },
    {
      path: sermon3,
      alt: "image3",
      date: "January 29",
      txt: "Attentive chanting means to always think that holy name is not different from Krishna and to carefully avoid the offenses towards the holy name.",
      id: "03",
    },
  ];

  return (
    <div className="App">
      <Carousel indicators={false} touch={true}>
        {images.map(({path, alt, id}) => (
          <Carousel.Item key={id} id={id}>
            <Image src={path} alt={alt} className="d-block w-100" />
            <Carousel.Caption>
              {/* <Button className="btn-offering" size="lg" href="/our-offerings">
                SUBMIT YOUR VYAS PUJA OFFERINGS
              </Button> */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <Container>
        <div className="text-center mt-5">
          <h2 className="">Bhagvatpadastakam</h2>
          <Row className="my-5 mx-auto d-flex justify-content-between">
            <Col
              className="d-flex justify-content-end send-offerings"
              sm={12}
              md={6}
              variant='success'
            >
              <Button
                target="_blank"
                href="https://iskcongkg.s3.ap-south-1.amazonaws.com/e-bookpdf/Bhagavatpadashatakam-Hindi.pdf" className="btn-offering hindi-btn txt-large py-3 rounded-0"
                size="lg"
              >
                Download (Hindi)
              </Button>
            </Col>
            <Col sm={12} md={6} className="d-flex sample-offerings">
              <Button
                className="py-3 rounded-0 btn-offering txt-large"
                variant="success"
                target="_blank"
                size="lg"
                href="https://iskcongkg.s3.ap-south-1.amazonaws.com/e-bookpdf/Bhagavatpadashatakam-English.pdf"
              >
                Download (English)
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <h2 className="text-center my-5 photo-gallery">Photo Gallery</h2>
        <Row className="gallery-wrapper d-flex py-1">
          <Col className="gallery-col px-0">
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[0].alt}
                src={gallery[0].path}
              />
            </div>
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[1].alt}
                src={gallery[1].path}
              />
            </div>
          </Col>
          <Col className="gallery-col px-0">
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[2].alt}
                src={gallery[2].path}
              />
            </div>
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[5].alt}
                src={gallery[5].path}
              />
            </div>
          </Col>
          <Col className="gallery-col px-0">
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[3].alt}
                src={gallery[3].path}
              />
            </div>
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[4].alt}
                src={gallery[4].path}
              />
            </div>
          </Col>
          <Col className="gallery-col px-0">
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[6].alt}
                src={gallery[6].path}
              />
            </div>
            <div className="gallery-col-wrapper">
              <Image
                className="ht-1"
                alt={gallery[7].alt}
                src={gallery[7].path}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-5 my-5 testimonials">
        <Row>
          <Col md={5} className="d-flex justify-content-center prabhupada-img">
            <Image className="h-100 w-100" src={pic} alt="prabhupada" />
          </Col>
          <Col md={7}>
            <Row className="h-25">
              <Col></Col>
            </Row>
            <Row className="h-75">
              <Col className="poster_color text-white testimonials-quotes d-flex justify-content-end flex-column">
                <Image src={quote} alt="quote" className="pb-3 quotes" />
                <Carousel
                  controls={false}
                  className="testimonials-text pb-3"
                  interval={null}
                >
                  {quotes.map((item, i) => (
                    <Carousel.Item key={`item-${i}`}>
                      <p>{item}</p>
                      <h6>SRILA PRABHUPADA</h6>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5 py-5 sermons-container">
        <Container>
          <h1 className="txt-blue text-center p-5 mb-5">Latest Sermons</h1>
          <Row>
            {sermons.map((item) => (
              <Col
                className="sermons-wrapper"
                key={item.id}
                id={item.id}
                md={4}
              >
                <div className="sermons-img-container">
                  <div
                    className="sermons-img"
                    style={{backgroundImage: `url(${item.path})`}}
                  ></div>
                </div>
                <Card className="text-center w-75 shadow-lg mx-auto sermons-cards">
                  <Card.Body className="px-4 py-5">
                    <Card.Title className="small">
                      <Nav.Link
                        className="text-decoration-none txt-orange"
                        href="#"
                      >
                        {item.date}
                      </Nav.Link>
                    </Card.Title>
                    <h5 className="txt-blue">{item.txt}</h5>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default App;
