/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Row, Col, Form, Container, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import schema from "./schema";
import { makeApiCallFor } from "../../api";
import {
  initialFormVal,
  genders,
  initiationTypes,
} from "./utils/initialFormValue";
import {
  blockInvalidChar,
  capitalizeWords,
  getUserDetails,
  getName,
  notNullNotUndefinedNotEmpty,
} from "./utils/mix";

export default function FormStatus() {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [temples, setTemples] = useState([]);

  const [isUserExist, setIsUserExist] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);

  let userId;

  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, defaultValues, isSubmitting },
  } = useForm({
    defaultValues: initialFormVal,
    resolver: yupResolver(schema),
  });

  let initFormValue = watch(defaultValues);

  useEffect(() => {
    async function getCountries() {
      let countryResp = await makeApiCallFor("getCountry");
      setCountries(countryResp);
    }
    getCountries();
  }, []);

  useEffect(() => {
    async function updateData() {
      const { countryId, stateId, templeId } = initFormValue;

      let stateResp = await makeApiCallFor("getStateByCountry", countryId);
      let cityResp = await makeApiCallFor("getCityByState", stateId);
      let templeResp = await makeApiCallFor("getTemplesByState", stateId);

      setStates(stateResp);
      setCities(cityResp);
      setTemples(templeResp);

      stateId && templeResp.length >= 0
        ? setValue("templeId", "")
        : setValue("otherTempleName", "");

      if (+templeId) {
        setValue("otherTempleName", "");
      }
    }
    updateData();
  }, [initFormValue.countryId, initFormValue.stateId, initFormValue.templeId]);

  useEffect(() => {
    if (!initFormValue.isInitiated) {
      setValue("initiatedName", "");
      setValue("initiatedType", "");
      setValue("initiatedYear", "");
    }
  }, [initFormValue.isInitiated]);

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (initFormValue.email.length) {
        const checkUser = await makeApiCallFor(
          "isUserExist",
          initFormValue.email
        );
        setIsUserExist(checkUser);
        await populateUserData();
        // Note if u add any of these symbols only in email field then edit button will enabled +,#,.,/,?
      }
    }, 1000);

    const populateUserData = async () => {
      if (isUserExist) {
        const getUserData = await makeApiCallFor(
          "getUserData",
          initFormValue.email
        );
        await getStateCityTemple(getUserData.countryId, getUserData.stateId);
        setValue("mobile", getUserData.phoneNumber);
        setValue("gender", getUserData.gender);
        setValue("firstName", getUserData.firstName);
        setValue("lastName", getUserData.lastName);
        setValue("countryId", getUserData.countryId);
        setValue("stateId", getUserData.stateId);
        setValue("cityId", getUserData.cityId);
        setValue("templeId", getUserData.templeId);
        if (getUserData.spiritualName) {
          setValue("isInitiated", true);
          setValue("initiatedName", getUserData.spiritualName);
          setValue("initiatedType", getUserData.initiationType);
          setValue("initiatedYear", getUserData.initiationYear);
        }
        if (getUserData.templeId) {
          MySwal.fire({
            icon: "warning",
            title: (
              <>
                <h2>
                  {notNullNotUndefinedNotEmpty(getUserData.spiritualName) ? (
                    <strong>{`Welcome HG ${getUserData.spiritualName}`}</strong>
                  ) : (
                    <strong>{`Welcome ${getUserData.firstName} ${getUserData.lastName}`}</strong>
                  )}
                </h2>
                <br />
                <p>Would you like to upload your offering with saved data?</p>
              </>
            ),
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              setIsDisabled(true);
              setEditForm(true);
            } else if (result.isDenied) {
              setIsDisabled(true);
              setEditForm(false);
            }
          });
        }
      } else {
        console.log("User doesn't exist");
      }
    };

    return () => clearTimeout(interval);
  }, [initFormValue.email, isUserExist]);

  useEffect(() => {
    //This is a quick fix for displaying state city and temples because these values where supposed to set on next re-render
    if (initFormValue) {
      setValue("stateId", initFormValue.stateId);
      setValue("cityId", initFormValue.cityId);
      setValue("templeId", initFormValue.templeId);
    }
  }, [states, cities, temples]);

  async function getStateCityTemple(c, s) {
    const stateResp = await makeApiCallFor("getStateByCountry", c);
    const cityResp = await makeApiCallFor("getCityByState", s);
    const templeResp = await makeApiCallFor("getTemplesByState", s);

    setStates(stateResp);
    setCities(cityResp);
    setTemples(templeResp);
  }

  const mapResponse = (args, name) => {
    const convert = name.replace("Id", "Name");
    return Array.isArray(args?.data) && args?.data.length ? (
      <>
        <Form.Select
          id={name}
          {...register(name)}
          disabled={editForm}
        >
          <option value="">--Select--</option>
          {args?.data.map((val) => (
            <option
              key={val.id}
              value={val.id}
            >
              {capitalizeWords(val[convert])}
            </option>
          ))}
          <option value="0">Other</option>
        </Form.Select>
      </>
    ) : (
      <>
        <Form.Select
          id={name}
          {...register(name)}
        >
          <option value="">Select</option>
          <option value="0">Other</option>
        </Form.Select>
      </>
    );
  };

  async function offeringType(type, data) {
    const unique = new FormData();
    let extension = initFormValue.offering[0]?.name.split(".").pop();

    let cntryNme = await getName(countries, "countryId", initFormValue);
    let stNme = await getName(states, "stateId", initFormValue);
    let ctNme = await getName(cities, "cityId", initFormValue);
    let tpNme = await getName(temples, "templeId", initFormValue);

    unique.append(
      "file",
      initFormValue.offering[0],
      `gkgVyasPuja_2024.${extension}`
    );
    unique.append("country", cntryNme[0]?.countryName);
    unique.append("state", stNme[0]?.stateName);
    unique.append("city", ctNme[0]?.cityName || "");
    unique.append(
      "temple",
      initFormValue?.otherTempleName || tpNme[0]?.templeName
    );
    unique.append(
      "name",
      initFormValue.initiatedName || initFormValue.firstName
    );

    let userResponse;
    try {
      if (type === "add") {
        userResponse = await makeApiCallFor("addUser", data);
      } else {
        userResponse = await makeApiCallFor("updateUser", data);
      }

      userId = await userResponse.id;
      const fileData = await makeApiCallFor("uploadOffering", unique);
      const obj = {
        userId: userId,
        templeId: +initFormValue.templeId,
        templeName: tpNme[0]?.templeName || initFormValue.otherTempleName,
        offeringFile: fileData.url,
        initiationType: initFormValue.initiatedType,
        initiationYear: initFormValue.initiatedYear,
      };
      const addOffering = await makeApiCallFor("addOffering", obj);

      if (Object.keys(addOffering).length > 0) {
        MySwal.fire({
          icon: "success",
          title: "Hare Krishna",
          html: "<p><strong>You have successfully submitted your offering.<br/>Thank you!</strong></p>",
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            setIsUserExist(false);
            setIsDisabled(false);
            reset();
          }
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Hare Krishna",
        html: <p>Uploading failed. Please try after sometime.</p>,
      });
      console.log(error.message);
    }
  }

  const submitStatus = async (data, ev) => {
    ev.preventDefault();

    let obj = { ...data };
    delete obj.otherTempleName;

    let userInfo = Object.values(obj).filter(Boolean);

    if (data.isInitiated ? userInfo.length === 14 : userInfo.length === 10) {
      // debugger;
      try {
        await offeringType(
          isUserExist ? "update" : "add",
          getUserDetails(initFormValue)
        );
      } catch (error) {
        console.warn("Fetching failed because of =", error);
      }
    } else {
      console.log("Validation issue", userInfo);
    }
  };

  const errorStatus = (data) => console.log(data);

  return (
    <Container className="my-5">
      <Form
        name="offering-form"
        noValidate
        onSubmit={handleSubmit(submitStatus, errorStatus)}
      >
        {isUserExist && (
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                size="lg"
                onClick={() => setEditForm(!editForm)}
              >
                <i className="bi bi-pencil-square"> </i>Edit
              </Button>
            </Col>
          </Row>
        )}
        <Row className="my-5">
          <Col
            sm={12}
            md={4}
          >
            <Form.Group>
              <Form.Label htmlFor="inp-email">
                Email/ई-मेल<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                id="inp-email"
                type="email"
                placeholder="Enter your email"
                {...register("email")}
                autoComplete="off"
                disabled={isDisabled}
              />
              <Form.Text className="text-danger">
                {errors.email?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            sm={12}
            md={4}
          >
            <Form.Group>
              <Form.Label htmlFor="inp-mobile">
                Mobile/मोबाइल<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                id="inp-mobile"
                type="number"
                onKeyDown={blockInvalidChar}
                placeholder="Enter your mobile number"
                {...register("mobile")}
                disabled={editForm}
              />
              <Form.Text className="text-danger">
                {errors.mobile?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            sm={12}
            md={4}
          >
            <Form.Group>
              <Form.Label htmlFor="inp-gender">
                Gender/लिंग<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                id="inp-gender"
                {...register("gender")}
                disabled={isDisabled}
              >
                {genders?.map((val) => (
                  <option
                    key={val.id}
                    value={val.value}
                  >
                    {val.label}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">
                {errors.gender?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-5">
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="inp-fName">
                First Name/पहला नाम<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                id="inp-fName"
                placeholder="Please enter your first name"
                {...register("firstName")}
                disabled={isDisabled}
              />
              <Form.Text className="text-danger">
                {errors.firstName?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="inp-lName">
                Last Name/उपनाम<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                id="inp-lName"
                placeholder="Please enter your last name"
                {...register("lastName")}
                disabled={isDisabled}
              />
              <Form.Text className="text-danger">
                {errors.lastName?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-5">
          <Col
            sm={12}
            md={12}
          >
            <Form.Group>
              <Form.Check
                id="Are you Initiated?"
                size="lg"
                label="Are you Initiated/क्या आप दीक्षित है?"
                {...register("isInitiated")}
                disabled={isDisabled}
              />
            </Form.Group>
          </Col>
        </Row>

        {initFormValue.isInitiated && (
          <Row>
            <Col
              sm={12}
              md={4}
            >
              <Form.Group>
                <Form.Label htmlFor="inp-initiatedName">
                  Initiated Name/दीक्षित नाम
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="inp-initiatedName"
                  placeholder="Please type your initiated name"
                  {...register("initiatedName")}
                  disabled={isDisabled}
                />
                <Form.Text className="text-danger">
                  {errors.initiatedName?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col
              sm={12}
              md={4}
            >
              <Form.Group>
                <Form.Label htmlFor="inp-initiatedType">
                  Initiated Type/दीक्षा<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  id="inp-initiatedType"
                  {...register("initiatedType")}
                  disabled={editForm}
                >
                  {initiationTypes.map((val) => (
                    <option
                      key={val.id}
                      value={val.value}
                    >
                      {val.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.initiatedType?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col
              sm={12}
              md={4}
            >
              <Form.Group>
                <Form.Label htmlFor="inp-initiatedYear">
                  Initiated Year/दीक्षा का वर्ष
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="inp-initiatedYear"
                  type="number"
                  placeholder="Please type your initiated year"
                  onKeyDown={blockInvalidChar}
                  {...register("initiatedYear")}
                  disabled={editForm}
                />
                <Form.Text className="text-danger">
                  {errors.initiatedYear?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="my-5">
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="countryId">
                Country/देश<span className="text-danger">*</span>
              </Form.Label>
              {mapResponse(countries, "countryId")}
              <Form.Text className="text-danger">
                {errors.countryId?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="stateId">
                State/राज्य<span className="text-danger">*</span>
              </Form.Label>
              {mapResponse(states, "stateId")}
              <Form.Text className="text-danger">
                {errors.stateId?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-5">
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="cityId">
                City/शहर<span className="text-danger">*</span>
              </Form.Label>
              {mapResponse(cities, "cityId")}
              <Form.Text className="text-danger">
                {errors.cityId?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            md={6}
            sm={12}
          >
            <Form.Group>
              <Form.Label htmlFor="templeId">
                Temple/मन्दिर<span className="text-danger">*</span>
              </Form.Label>
              {mapResponse(temples, "templeId")}
              <Form.Text className="text-danger">
                {errors.templeId?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        {initFormValue.templeId === "0" && (
          <Row className="my-5">
            <Col
              md={6}
              sm={12}
            >
              <Form.Group>
                <Form.Label htmlFor="inp-otherTemple">
                  Other Temple Name/अन्य मन्दिर नाम
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="inp-otherTemple"
                  placeholder="Please type your temple name"
                  {...register("otherTempleName")}
                  disabled={editForm}
                />
                <Form.Text className="text-danger">
                  {errors.otherTempleName?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="my-5">
          <Col
            md={12}
            sm={12}
          >
            <Form.Group>
              <Alert variant="warning">
                <strong className="text-black">Note:</strong> Please upload
                document in <strong className="text-black">doc</strong> or{" "}
                <strong className="text-black">docx</strong> format only! File
                size must be <strong className="text-black">2MB</strong> max!
              </Alert>
              <Form.Label htmlFor="inp-offering">Select file</Form.Label>
              <Form.Control
                id="inp-offering"
                className="inputFile"
                type="file"
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                {...register("offering")}
                // onChange={(ev) => setOffering(ev.target.files[0])}
              />
              <Form.Text className="text-danger">
                {errors.offering?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-5">
          <Col>
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={isSubmitting ? "secondary" : "primary"}
            >
              Submit Offering
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
