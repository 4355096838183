import React from "react";
import Banner from "../Components/Banner";
import pic from "../../assets/images/sermon1.jpg";
import pic2 from "../../assets/images/about1.jpg";
import pic3 from "../../assets/images/about2.jpg";
import pic4 from "../../assets/images/about3.jpg";
import pic5 from "../../assets/images/07.jpg";
import { Container, Row, Col, Image, Card, ListGroup } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <>
      {/* <Banner txt="About" /> */}
      <Container className="py-5">
        <Row className="pb-5">
          <Col lg={5}>
            <Image
              className="w-100"
              src={pic}
              alt="guru-maharaj"
            />
          </Col>
          <Col
            lg={7}
            className="my-auto about-text"
          >
            <h4 className="txt-blue pb-3">Glories of Guru Maharaja.</h4>
            <p>
              All these spiritual activities are just a glimpse of H. H. Gopal
              Krishna Goswami Maharaja's deep commitment and surrender to Srila
              Prabhupada, his untiring, dedicated efforts, his selfless
              compassionate nature, and inner conviction to spread the mission
              of Lord Caitanya Mahaprabhu globally. To fulfil the dream of
              Prabhupada's H. H. Gopal Krishna Goswami Maharaj extends his
              travels to spread Krishna consciousness more widely in parts of
              the USA, Canada and eventually in parts of Africa.
            </p>
            <p>
              Currently he is GBC of over thirty temples around the world and
              serves as an initiating spiritual master, with disciples in
              Russia, America, Canada, Europe, Asia, Africa, Australia and New
              Zealand. H. H. Gopal Krishna Goswami Maharaja spirited enthusiasm
              makes even a skeptic pause and contemplate on his words.
            </p>
            <p>
              His aims are so clear! Many are overwhelmed by his selfless and
              compassionate intellect, as they strive towards becoming virtuous
              people. Srila Prabhupada always admired his qualities of head and
              heart, and he once remarked, “Gopal is a very nice boy and he must
              be encouraged“. Even hardcore atheists are attracted to his
              graceful simplicity. Every encounter, every gesture, and every
              answer brings out the inner conviction he has, in the mission he
              has embraced.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="appearance-wrapper py-5"
      >
        <Container>
          <h3 className="text-center txt-blue">Appearance</h3>
          <ul className="text-white px-5 appearance-list">
            <li className="py-3">
              His Holiness Gopal Krishna Goswami Maharaja appeared on this
              planet on the most auspicious day of Annada Ekadashi, on August
              14, 1944, in New Delhi, India.
            </li>
            <li className="py-3">
              After graduating from Delhi University in 1964, Gopal Krishna
              Goswami studied business management in France and later, obtained
              a Masters Degree in Business Administration in Montreal.
            </li>
          </ul>
        </Container>
      </Container>
      <Container
        fluid
        className="bg-about"
      >
        <Container className="pb-5">
          <h4 className="text-center txt-blue py-5">Meeting with Prabhupada</h4>
          <ListGroup>
            <ListGroup.Item className="bg-list">
              <i className="bi bi-arrow-right"></i> &nbsp;After visiting the
              ISKCON temple in Montreal, Canada in September of 1967, Gopal
              Krishna Goswami yearned to meet a Vaishnava guru and got the
              opportunity to meet Srila Prabhupada there in 1968.
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              <i className="bi bi-arrow-right"></i> &nbsp;H. H. Gopal Krishna
              Goswami Maharaja first met Srila Prabhupada in the evening of June
              1st, 1968. Within the first two weeks of meeting Srila Prabhupada,
              he decided that he would accept him as his spiritual master
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              <i className="bi bi-arrow-right"></i> &nbsp;Srila Prabhupada was
              always sympathetic and extra-merciful to Indians, but of all the
              Indians who came to hear him, young Gopal Krishna was very
              special. While all the other Indians would offer obeisances and
              sit for a few minutes, H. H. Gopal Krishna Goswami Maharaja was
              the only Indian who would come regularly and sit right till the
              end of the lecture.
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              <i className="bi bi-arrow-right"></i> &nbsp;Srila Prabhupada
              started taking a great personal interest in him and would talk to
              him almost every day. Since then, both of them started a regular
              correspondence and would communicate via writing letters thus a
              very intimate guru-shishya relationship had begun to establish
              between them.
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </Container>
      <Container>
        <h3 className="text-center p-5 mt-5">
          Harinama initiation and Book distribution service
        </h3>
        <Row className="pb-5">
          <Col
            md={8}
            className="my-auto"
          >
            <ul>
              <li>
                On May 27th, 1969, Srila Prabhupada wrote to say, "As your name
                is already Gopal Krishna, there is no need for changing it. You
                will now be known as Gopal Krishna Das." H.H. Gopal Krishna
                Goswami Maharaja was only 25 years old then
              </li>
              <li>
                Later on, H.H. Gopal Krishna Goswami Maharaja moved into the
                temple and engaged himself fully in the service of Srila
                Prabhupada. For many years, he acted as Srila Prabhupada's
                personal secretary
              </li>
              <li>
                Gopal Krishna Goswami began contributing to ISKCON by
                financially assisting the Montreal temple.
              </li>
            </ul>
          </Col>
          <Col
            md={4}
            className="my-auto"
          >
            <div className="border image1 border-2 border-dark p-1 rounded-3">
              <Image
                alt="maharaj giving initiation"
                src={pic2}
                className="w-100 rounded-3"
              />
            </div>
          </Col>
        </Row>
        <Row className="pb-5 mb-5">
          <Col
            md={4}
            className="my-auto image2"
          >
            <div className="border border-2 border-dark p-1 rounded-3">
              <Image
                alt="maharaj gifting bhagawat geeta"
                src={pic3}
                className="w-100 rounded-3"
              />
            </div>
          </Col>
          <Col md={8}>
            <ul>
              <li>
                In December of 1974 he was made a GBC (governing body
                commission) member by Srila Prabhupada and then transferred to
                India where he initially headed projects in Mumbai, Delhi,
                Gujarat, Hyderabad, Madras, Mayapur and Kanpur.
              </li>
              <li>
                Srila Prabhupada specifically instructed him to print and expand
                book-distribution in India and other countries.
              </li>
              <li>
                In 1976, Srila Prabhupada appointed H. H. Gopal Krishna Goswami
                Maharaja as G.B.C. for Russia. He made several trips to the
                Soviet Union, a communist state (now called the Commonwealth of
                Independent States) and risking grave dangers, he preached and
                distributed books over there. Under his expert supervision, His
                Holiness has taken the distribution of books to the highest
                limits in the history of ISKCON! Maharaja goes to different
                temples, before the Marathon, to inspire everyone to distribute
                books
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container
        className="bg-about"
        fluid
      >
        <Container>
          <h4 className="text-center pt-5 mt-5">Sannyasa order</h4>
          <Row className="mt-5">
            <Col
              md={6}
              className="justify-content-end left-card d-flex"
            >
              <Card className="me-auto shadow px-3 card-wrapper">
                <Card.Img
                  alt="maharaj"
                  src={pic4}
                  className=""
                />
                <Card.Body>
                  <Card.Title>
                    <p className="small">
                      In 1981, H. H. Gopal Krishna Goswami Maharaja took the
                      sannyasa order.
                    </p>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow px-3 card-wrapper">
                <Card.Img
                  alt="maharaj"
                  src={pic5}
                  className=""
                />
                <Card.Body>
                  <Card.Title>
                    <p className="small">
                      A year later, in March 1982, on the auspicious day of
                      Gaura-Purnima, H. H. Gopal Krishna Goswami Maharaja became
                      a 'diksha' guru
                    </p>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <h2 className="text-center txt-blue pt-5 mt-5">
            Construction of temples and other services
          </h2>
          <ListGroup className="py-5 mt-5">
            <ListGroup.Item className="bg-list">
              After the departure of Srila Prabhupada in 1977 Gopal Krishna
              Goswami played a leading role in inspiring and completing several
              large ISKCON temples such as the Glory of India temple in New
              Delhi in 1998 (inaugurated by then Prime Minister of India Atal
              Bihari Vajpayee), ISKCON Pune (inaugurated by the President of
              India Pranab Mukherjee) and the Radha Bankebihari temple in
              Nairobi and many other such temples all over the world.
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              Besides attracting thousands of national and international
              tourists and devotees, these temples are playing a leading role in
              spreading the divine message of Srimad Bhagavad-gita As It Is and
              also other vedic literatu
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              Inspired by Srila Prabhupada's instruction given to his disciples
              in 1974 that, "no one within ten miles radius of the temple should
              go hungry" Maharaj immediately begin serving food.
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              ISKCON centers distribute sumptuous Krishna prasadam on a daily
              basis and also have actively taken up to Mid-day Meal Program of
              the Government as a medium to distribute Krishna prasadam.
            </ListGroup.Item>
            <ListGroup.Item className="bg-list">
              Other activities which take place are weekly seminars which are
              being conducted, restaurants are being developed, regular
              preaching programs and youth cultivation program are being
              conducted in schools and colleges through which people are
              becoming favorable to the movement.
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </Container>
    </>
  );
};

export default About;
