import axios from "axios";

const baseURL = "https://gkgvyasapuja-api.azurewebsites.net/api";

export const handleAPI = async (url, method, data) => {
  let response;
  try {
    if (method === "get") {
      response = await axios.get(`${baseURL}/${url}`);
    } else {
      response = await axios.post(
        `${baseURL}/${url}`,
        data,
        !url.includes("upload-offering") && {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
    const result = await response.data;
    return result;
  } catch (error) {
    return error.message;
  }
};

export async function makeApiCallFor(args, data) {
  switch (args) {
    case "getCountry": {
      return await handleAPI(endpoints.GET_COUNTRY, "get");
    }
    case "getTemples": {
      return await handleAPI(endpoints.GET_TEMPLES, "get");
    }
    case "getStateByCountry": {
      return await handleAPI(endpoints.GET_STATE_BY_COUNTRY + data, "get");
    }
    case "getCityByState": {
      return await handleAPI(endpoints.GET_CITY_BY_STATE + data, "get");
    }
    case "getTemplesByState": {
      return await handleAPI(endpoints.GET_TEMPLES_BY_STATE + data, "get");
    }
    case "getBooks": {
      return await handleAPI(endpoints.GET_BOOKS, "get");
    }
    case "getUserData": {
      return await handleAPI(endpoints.GET_USER_DATA + data, "get");
    }
    case "addUser": {
      return await handleAPI(endpoints.ADD_USER, "post", data);
    }
    case "updateUser": {
      return await handleAPI(endpoints.UPDATE_USER, "post", data);
    }
    case "isUserExist": {
      return await handleAPI(endpoints.IS_USER_EXIST + data, "get");
    }
    case "addOffering": {
      return await handleAPI(endpoints.ADD_OFFERING, "post", data);
    }
    case "addOtherOffering": {
      return await handleAPI(endpoints.OTHERS_OFFERING, "post", data);
    }
    case "uploadOffering": {
      return await handleAPI(endpoints.UPLOAD_OFFERING, "post", data);
    }
    case "apiStatus": {
      return await handleAPI(endpoints.API_STATUS, "post", data);
    }
    default:
      return "Invalid case name!";
  }
}

const endpoints = {
  ADD_USER: "User/add-user",
  UPDATE_USER: "User/update-user",
  IS_USER_EXIST: `User/exists/`, //to check whether user is existing or not
  GET_USER_DATA: `User/`, //if user exist then get his data
  ADD_OFFERING: "Offering/add-offering",
  UPLOAD_OFFERING: "Offering/upload-offering",
  OTHERS_OFFERING: "Offering/add-others-offering",
  GET_COUNTRY: "Temple/GetCountry",
  GET_TEMPLES: "Temple/GetTemples",
  GET_STATE_BY_COUNTRY: `Temple/GetStateByCountry?id=`, //put country id
  GET_CITY_BY_STATE: `Temple/GetCityByState?id=`, //put state id
  GET_TEMPLES_BY_STATE: `Temple/GetTemplesByState?id=`, // put state id
  GET_BOOKS: "Book/GetBooks",
  API_STATUS: "Activity/add-activity",
  // VERIFY_EMAIL: `Authenticate/send-verification-email/`,
};

export const sample_offering =
  "https://gkgvyasapuja.blob.core.windows.net/offerings/Sample%20Offering%20English%20And%20Hindi.pdf";
