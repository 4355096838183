import * as yup from "yup";

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .required("Email is mandatory")
    .email()
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
      "Please enter your email in correct format!"
    ),
  mobile: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test("mobile", "Phone number must be between 4 and 15 digits", (value) => {
      if (!value) {
        return true;
      }
      return /^\+?\d{4,15}$/.test(value);
    })
    .required("Mobile number is mandatory"),
  firstName: yup.string().trim().required("First name is mandatory"),
  lastName: yup.string().trim().required("Last name is mandatory"),
  isInitiated: yup.boolean().notRequired(),
  gender: yup.string().required("Please select your gender"),
  countryId: yup.string().required("Please select your country"),
  stateId: yup.string().required("Please select your state"),
  cityId: yup.string().required("Please select your city"),
  templeId: yup.string().required("Please select your temple"),
  offering: yup
    .mixed()
    .test("required", "Please upload your offering file", (value) => {
      if (value) return true;
      return false;
    })
    .test("fileFormat", "Only doc or docx are allowed!", (value) => {
      if (value) {
        return ["docx", "doc"].includes(value[0]?.name.split(".").pop());
      } else {
        return false;
      }
    })
    .test("fileSize", "File size must be 1MB maximum", (value) => {
      if (value) {
        return value[0]?.size <= 1048576;
      } else {
        return false;
      }
    }),
  otherTempleName: yup.string().when("templeId", (value) => {
    if (value[0] === "0") {
      return yup.string().required("Temple name is mandatory");
    } else {
      return yup.string().notRequired();
    }
  }),
  initiatedName: yup
    .string()
    .trim()
    .when("isInitiated", (value) => {
      if (value[0]) {
        return yup.string().required("Initiated name is mandatory");
      } else {
        return yup.string().notRequired();
      }
    }),
  initiatedType: yup.string().when("isInitiated", (val) => {
    if (val[0]) {
      return yup.string().required("Please select your initiation type");
    } else {
      return yup.string().notRequired();
    }
  }),
  initiatedYear: yup.number().when("isInitiated", (val) => {
    if (val[0]) {
      return yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .test(
          "initiatedYear",
          "Year must be exactly 4 digits",
          (value) => value.toString().length === 4
        )
        .required("Initiation year is mandatory");
    } else {
      return yup.string().notRequired();
    }
  }),
  /* offeringType: yup
    .string()
    .transform((val) => (val === "" ? null : val))
    .nullable()
    .required("Please select offering type!"), */
});

export default schema;
