export const notNullNotUndefinedNotEmpty = (args) => {
  return args !== null && args !== undefined && args !== "";
};

export const blockInvalidChar = (e) =>
  ["E", "e", "+", "-", "."].includes(e.key) && e.preventDefault();

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const removeSpaces = (args) =>
  notNullNotUndefinedNotEmpty(args) &&
  args.trim().split(" ").filter(Boolean).join(" ");

export function getUserDetails(data) {
  return {
    userName: removeSpaces(data.email),
    firstName: removeSpaces(data.firstName),
    lastName: removeSpaces(data.lastName),
    email: removeSpaces(data.email),
    spiritualName: removeSpaces(data?.initiatedName) || "",
    phoneNumber: data.mobile,
    gender: data.gender,
    countryId: +data.countryId,
    stateId: +data.stateId,
    cityId: +data.cityId,
    password: "Hare_Krishna_1",
  };
}

export function getName(args, value, data) {
  return (
    notNullNotUndefinedNotEmpty(args) &&
    args?.data.filter((val) => val.id === parseInt(data[value]))
  );
}

export function logDetails(args) {
  return {
    id: "",
    date: new Date().toISOString(),
    response: "",
    user: args.email,
    action: args.message,
    details: `${args.api} api got failed!`,
  };
}
