/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Form, Image } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import LoadingOverlay from "react-loading-overlay";
import GridLoader from "react-spinners/GridLoader";
import img from "../../assets/images/20945385.jpg";

export default function UploadOfferings() {
  //form values
  const [isInitiated, setIsInitiated] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spiritualName, setSpiritualName] = useState("");
  const [initiationType, setInitiationType] = useState("");
  const [initiationYear, setInitiationYear] = useState("");
  const [otherTempleName, setOtherTempleName] = useState("");
  const [templeId, setTempleId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [templeName, setTempleName] = useState("");
  const [doc, setDoc] = useState("");

  //api collections
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [temples, setTemples] = useState([]);

  const [isUserExist, setIsUserExist] = useState(null);
  const [emailMsg, setEmailMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [userID, setUserID] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isemailConfirmationRequired] = useState(true);
  const [emailConfirmed, setemailConfirmed] = useState(true);
  const [uploadingOffering, setuploadingOffering] = useState(false);
  const [templeMsg, setTempleMsg] = useState("");
  const [offeringType, setOfferingType] = useState("");
  const [shouldDisplay, setShouldDisplay] = useState(true);

  //if uploading is done for other users
  const [oFirstName, setOFirstName] = useState("");
  const [oLastName, setOLastName] = useState("");
  const [oSpiritualName, setOSpiritualName] = useState("");
  const [oInitiationType, setOInitiationType] = useState("");
  const [oInitiationYear, setOInitiationYear] = useState("");
  const [oIsInitiated, setOIsInitiated] = useState(false);
  const [oCountryId, setOCountryId] = useState("");
  const [oCountryName, setOCountryName] = useState("");
  const [oStateId, setOStateId] = useState("");
  const [oStateName, setOStateName] = useState("");
  const [oCityId, setOCityId] = useState("");
  const [oCityName, setOCityName] = useState("");
  const [oTempleId, setOTempleId] = useState("");
  const [oTempleName, setOTempleName] = useState("");
  const [oOtherTempleName, setOOtherTempleName] = useState("");
  const [oOtherFile, setOOtherFile] = useState("");
  const [oGender, setOGender] = useState("");

  const [inMaintainenceMode, setInMaintainenceMode] = useState(false);
  const [extension, setExtension] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const checkRef = useRef();
  const fileRef = useRef(null);
  const oFileRef = useRef(null);
  const oCheckRef = useRef();
  const MySwal = withReactContent(Swal);
  let token = "";
  const addUser =
    "https://gkgvyasapuja-api.azurewebsites.net/api/User/add-user";
  const updateUser =
    "https://gkgvyasapuja-api.azurewebsites.net/api/User/update-user";
  const addOfferings =
    "https://gkgvyasapuja-api.azurewebsites.net/api/Offering/add-offering";
  const uploadOfferings =
    "https://gkgvyasapuja-api.azurewebsites.net/api/Offering/upload-offering";
  const addOtherOfferings =
    "https://gkgvyasapuja-api.azurewebsites.net/api/Offering/add-others-offering";
  const files = new FormData();
  let getID;
  let userDbId;

  function options(args) {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(args),
    };
  }

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const notNullNotUndefinedNotEmpty = (args) => {
    return args !== null && args !== undefined && args !== "";
  };

  useEffect(() => {
    /* Get all the countries on page load */
    fetch("https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetCountry")
      .then((res) => res.json())
      .then((val) => setCountries(val.data));

    fetch("https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetTemples")
      .then((res) => res.json())
      .then((data) => setTemples(data.data));
  }, []);

  const handleCountryChange = async (e) => {
    const id = e.target.value,
      name = e.target.selectedOptions[0].innerHTML;
    if (notNullNotUndefinedNotEmpty(id)) {
      try {
        const response = await fetch(
          `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetStateByCountry?id=${id}`
        );
        const value = await response.json();
        setStates(value.data);
        setCountryName(name);
        setCountryId(id);
        setOCountryId(id);
        setOCountryName(name);
      } catch (error) {
        console.log("Country fetching failed because of =", error);
      }
    }
  };

  const handleStateChange = (e) => {
    const id = e.target.value,
      name = e.target.selectedOptions[0].innerHTML;
    if (notNullNotUndefinedNotEmpty(id)) {
      setStateName(name);
      setStateId(id);
      setOStateId(id);
      setOStateName(name);
      fetch(
        `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetCityByState?id=${id}`
      )
        .then((res) => res.json())
        .then((val) => setCities(val.data));

      fetch(
        `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetTemplesByState?id=${id}`
      )
        .then((res) => res.json())
        .then((val) => setTemples(val.data));
    }
  };

  const handleCityChange = (e) => {
    const id = e.target.value,
      name = e.target.selectedOptions[0].innerHTML;
    setCityId(id);
    setCityName(name);
    setOCityId(id);
    setOCityName(name);
    console.log({ cityId, cityName });
  };

  const removeSpaces = (args) =>
    notNullNotUndefinedNotEmpty(args) &&
    args.trim().split(" ").filter(Boolean).join(" ");

  const handleSubmit = async (e) => {
    e.preventDefault();
    otherTempleName && setOtherTempleName(removeSpaces(otherTempleName));
    setFirstName(removeSpaces(firstName));
    setLastName(removeSpaces(lastName));
    setEmail(removeSpaces(email));
    spiritualName && setSpiritualName(removeSpaces(spiritualName));
    phoneNumber && handlePhoneChange(phoneNumber);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    files.append("file", doc, `gkgVyasPuja_2023.${extension}`);
    // files.append("file", doc); commented on 14-june-23
    files.append("country", countryName);
    files.append("state", stateName);
    files.append("city", cityName);
    files.append("temple", templeId === "0" ? otherTempleName : templeName);
    files.append(
      "name",
      notNullNotUndefinedNotEmpty(spiritualName) ? spiritualName : firstName
    );

    const userDetails = {
      userName: email,
      firstName,
      lastName,
      spiritualName,
      email,
      phoneNumber,
      gender,
      countryId: +countryId,
      stateId: +stateId,
      cityId: +cityId || 0,
      password: "Hare_Krishna_1",
    };

    const validateUser = {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      countryId,
      stateId,
      cityId: cityId || "0",
      temple: templeId === "0" ? otherTempleName : templeId,
      doc,
      spiritualName,
      initiationType,
      initiationYear,
    };

    if (
      isInitiated
        ? Object.values(validateUser).filter(Boolean).length === 13
        : Object.values(validateUser).filter(Boolean).length === 10
    ) {
      // debugger;
      setuploadingOffering(true);
      try {
        if (!isUserExist) {
          const userInfo = await fetch(addUser, options(userDetails));
          getID = await userInfo.json();
          userDbId = getID.id;
          const fileData = await fetch(uploadOfferings, {
            method: "POST",
            body: files,
          });
          const getURL = await fileData.json();
          const obj = {
            userId: userDbId,
            TempleId: +templeId || 0,
            TempleName: otherTempleName,
            offeringFile: getURL.url,
            initiationType,
            initiationYear,
          };
          const response3 = await fetch(addOfferings, options(obj));
          console.log({
            userInfo,
            userDbId,
            getURL,
            fileData,
            response3,
            userDetails,
          });
          if (response3.ok) {
            MySwal.fire({
              icon: "success",
              title: "Hare Krishna",
              html: "<p><strong>You have successfully submitted your offering.<br/>Thank you!</strong></p>",
            }).then((result) => {
              if (result.isConfirmed) {
                setuploadingOffering(false);
                handleClearForm();
              }
            });
          }
          //Verify User email
          /* if (isemailConfirmationRequired && !emailConfirmed) {
            debugger;
            // sendVerifyEmail();
            MySwal.fire({
              title:
                "Verify the code you recieved on the email before submitting your offering!",
              input: "number",
              icon: "warning",
              inputAttributes: {
                autocapitalize: "off",
              },
              inputPlaceholder: "Enter 6 digit OTP",
              showCancelButton: false,
              showDenyButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#ffffff00",
              confirmButtonText: (
                <Button
                  size="lg"
                  variant="success"
                >
                  <i class="bi bi-check"> </i>Verify OTP
                </Button>
              ),
              footer: (
                <Button
                  variant="warning"
                  size="lg"
                  onClick={sendVerifyEmail}
                >
                  <i class="bi bi-send"> </i>Resend OTP
                </Button>
              ),
              preConfirm: async (code) => {
                if (code === null) return false;
                if (code === "") {
                  MySwal.showValidationMessage("Please enter 6 digit OTP!");
                  return false;
                }
                if (code.toString() !== token.toString()) {
                  MySwal.showValidationMessage("Wrong code, Please try again.");
                  return false;
                } else {
                  MySwal.fire({
                    icon: "success",
                    title: `${email}- Email verification is successful, Please wait while your offering is being submitted.`,
                  });

                  //Upload offering after verification for new user

                  const fileData = await fetch(uploadOfferings, {
                    method: "POST",
                    body: files,
                  });
                  const getURL = await fileData.json();
                  const obj = {
                    userId: userDbId,
                    TempleId: +templeId || 0,
                    TempleName:
                      templeId === 0 ? otherTempleName.trim() : templeName,
                    offeringFile: getURL.url,
                    initiationType,
                    initiationYear,
                  };
                  const response3 = await fetch(addOfferings, options(obj));
                  if (response3.ok) {
                    MySwal.fire({
                      icon: "success",
                      title: "Hare Krishna",
                      html: "<p><strong>You have successfully submitted your offering.<br/>Thank you!</strong></p>",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setuploadingOffering(false);
                        handleClearForm();
                      }
                    });
                  }
                }
              },
            }).then((response) => {
              if (response.isDenied) {
                const tokenResponse = fetch(
                  `https://gkgvyasapuja-api.azurewebsites.net/api/Authenticate/send-verification-email/${email}`,
                  {
                    method: "POST",
                  }
                );
                const token = tokenResponse.json();
                if (token === null) return false;
                if (token === "") {
                  MySwal.showValidationMessage("Please enter 6 digit OTP!");
                  return false;
                }
                if (!token.toString()) {
                  MySwal.showValidationMessage("Wrong code, Please try again.");
                  return false;
                } else {
                  MySwal.fire({
                    icon: "success",
                    title: `${email}- Email verification is successful, Please wait while your offering is being submitted.`,
                  });

                  //Upload offering after verification for new user

                  const fileData = fetch(uploadOfferings, {
                    method: "POST",
                    body: files,
                  });
                  const getURL = fileData.json();
                  const obj = {
                    userId: userDbId,
                    TempleId: +templeId || 0,
                    TempleName: otherTempleName,
                    offeringFile: getURL.url,
                    initiationType,
                    initiationYear,
                  };
                  const response3 = fetch(addOfferings, options(obj));
                  if (response3.ok) {
                    MySwal.fire({
                      icon: "success",
                      title: "Hare Krishna",
                      html: "<p><strong>You have successfully submitted your offering.<br/>Thank you!</strong></p>",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setuploadingOffering(false);
                        handleClearForm();
                      }
                    });
                  }
                }
              }
            });
          } */
        } else {
          userDbId = userID;
          try {
            await fetch(updateUser, options(userDetails));
            const fileData = await fetch(uploadOfferings, {
              method: "POST",
              body: files,
            });
            const getURL = await fileData.json();
            const obj = {
              userId: userDbId,
              TempleId: +templeId || 0,
              TempleName: templeId === 0 ? otherTempleName.trim() : templeName,
              offeringFile: getURL.url,
              initiationType,
              initiationYear,
            };
            const response3 = await fetch(addOfferings, options(obj));
            if (response3.ok) {
              MySwal.fire({
                icon: "success",
                title: "Hare Krishna",
                html: "<p><strong>You have successfully submitted your offering.<br/>Thank you!</strong></p>",
              }).then((result) => {
                if (result.isConfirmed) {
                  setuploadingOffering(false);
                  handleClearForm();
                }
              });
            }
          } catch (er) {
            console.warn("Update failed because of =", er);
          }
        }
      } catch (er) {
        setuploadingOffering(false);
        MySwal.fire({
          icon: "error",
          title: (
            <p>Uploading of offering failed. Please try after sometime.</p>
          ),
        });
        console.warn("Fetching failed because of =", er);
      }
    } else {
      console.log("Validation issue");
      console.log(Object.entries(validateUser));
    }

    /* MySwal.fire({
      icon: "info",
      html: `<div class='text-start'>
        <h2>Hare Krishna,</h2><br/>
        <p>Dear devotees, please accept our humble obeisances.</p><p>As the last date for submitting offerings was <strong>30<sup>th</sup> June 2023</strong>, the website will not accept any offerings now. Also please do not send offerings through WhatsApp or Email as they will not be included in the book. The cutoff was necessary considering the time needed for editing, proofreading and book designing. Next year, we hope to receive your offerings at the earliest.</p>
      </div>`,
    }).then((val) => {
      if (val.isConfirmed || val.dismiss) {
        handleClearForm();
        handleClearOtherForm();
      }
    }); */
  };

  const handleClearForm = () => {
    setEmail("");
    setPhoneNumber("");
    setGender("");
    setFirstName("");
    setLastName("");
    setSpiritualName("");
    setInitiationType("");
    setInitiationYear("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setTempleId("");
    setOtherTempleName("");
    setIsInitiated(false);
    setIsUserExist(false);
    setDoc("");
    setValidated(false);
    setIsDisable(false);
    setEditable(false);
    setEmailMsg("");
    fileRef.current.value = null;
    checkRef.current.checked = false;
  };

  const handleOtherTemple = (e) => {
    const pattern = /^[A-Za-z\s]+$/;
    if (!otherTempleName.trim()) {
      setTempleMsg("Input cannot be empty");
    } else if (!pattern.test(otherTempleName)) {
      e.preventDefault();
      setTempleMsg("Input must contain only text and spaces");
      setOtherTempleName("");
    } else {
      setOtherTempleName(e.target.value);
      setTempleMsg("");
    }

    if (!oOtherTempleName.trim()) {
      setTempleMsg("Input cannot be empty");
    } else if (!pattern.test(oOtherTempleName)) {
      e.preventDefault();
      setTempleMsg("Input must contain only text and spaces");
      setOOtherTempleName("");
    } else {
      setOOtherTempleName(e.target.value);
      setTempleMsg("");
    }
  };

  const handleFile = (e) => {
    const files = e.target.files[0],
      size = 1024,
      verifiedSize = files.size / 1024,
      ext = files.name.split("."),
      val = ext[ext.length - 1];
    setExtension(val);

    console.log({ e, files, ext, val });

    if (val === "docx" || val === "doc") {
      setDoc(files);
      setOOtherFile(files);
    } else {
      MySwal.fire({
        icon: "error",
        text: "Please upload Word file only PDF file will not be accepted!",
      });
      fileRef.current.value = null;
      setDoc("");
      setOOtherFile("");
    }

    if (verifiedSize > size) {
      MySwal.fire({
        icon: "warning",
        text: "File size should not be greater than 1MB",
      });
      fileRef.current.value = null;
      return;
    }
  };

  //Method commented on 14-June-2023
  /* const handleFile = (e) => {
    const files = e.target.files[0],
      extension = files.name.split(".")[1],
      size = 1024,
      verifiedSize = files.size / 1024;

    if (extension === 'docx' || extension === 'doc') {
      setDoc(files);
      setOOtherFile(files);
    } else {
      MySwal.fire({
        icon: "error",
        text: "Please upload the documents with docx or doc only!",
      });
      fileRef.current.value = null;
      setDoc("");
      setOOtherFile("");
    }

    if (verifiedSize > size) {
      MySwal.fire({
        icon: "warning",
        text: "File size should be 1MB maximum",
      });
      fileRef.current.value = null;
      return;
    }
  }; */

  async function getStateCityTemple(c, s) {
    await fetch(
      `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetStateByCountry?id=${c}`
    )
      .then((res) => res.json())
      .then((val) => setStates(val.data));

    await fetch(
      `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetCityByState?id=${s}`
    )
      .then((res) => res.json())
      .then((data) => setCities(data.data));

    await fetch(
      `https://gkgvyasapuja-api.azurewebsites.net/api/Temple/GetTemplesByState?id=${s}`
    )
      .then((res) => res.json())
      .then((val) => setTemples(val.data));
    console.log({ states, cities, temples });
  }

  useEffect(() => {
    const checkUser = setTimeout(async () => {
      const response = await fetch(
        `https://gkgvyasapuja-api.azurewebsites.net/api/User/exists/${email}`
      );
      if (response.ok) {
        const data = await response.json();
        setIsUserExist(data);
        await setExistingUser();
      }
    }, 1000);

    async function setExistingUser() {
      if (isUserExist) {
        debugger;
        const res = await fetch(
          `https://gkgvyasapuja-api.azurewebsites.net/api/User/${email}`
        );
        const data = await res.json();
        console.log("Exisiting user data = ", data);
        await getStateCityTemple(data.countryId, data.stateId);
        setUserID(data.id);
        setemailConfirmed(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setPhoneNumber(data.phoneNumber);
        setCountryId(data.countryId);
        setCountryName(data.countryName);
        setStateId(data.stateId);
        setStateName(data.stateName);
        setCityId(data.cityId);
        setCityName(data.cityName);
        setTempleId(data.templeId);
        setTempleName(data.templeName);
        setGender(data.gender);
        if (data.spiritualName) {
          setIsInitiated(true);
          setSpiritualName(data.spiritualName);
          setInitiationType(data.initiationType);
          setInitiationYear(data.initiationYear);
        }
        if (data.templeId) {
          MySwal.fire({
            icon: "warning",
            title: (
              <>
                <h2>
                  {notNullNotUndefinedNotEmpty(data.spiritualName) ? (
                    <strong>{`Welcome HG ${data.spiritualName}`}</strong>
                  ) : (
                    <strong>{`Welcome ${data.firstName} ${data.lastName}`}</strong>
                  )}
                </h2>
                <br />
                <p>Would you like to upload your offering with saved data?</p>
              </>
            ),
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              setIsDisable(true);
              setEditable(true);
            } else if (result.isDenied) {
              setIsDisable(true);
              setEditable(false);
              //handleClearForm();
            }
          });
        }
      }
    }
    return () => clearTimeout(checkUser);
  }, [email, isUserExist]);

  const handleEmail = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    !pattern.test(email)
      ? setEmailMsg("Please enter valid email address")
      : setEmailMsg("");

    if (email === "") setEmailMsg("Email field cannot be empty");
  };

  const sendVerifyEmail = async () => {
    debugger;
    const tokenResponse = await fetch(
      `https://gkgvyasapuja-api.azurewebsites.net/api/Authenticate/send-verification-email/${email}`,
      {
        method: "POST",
      }
    );
    token = await tokenResponse.json();
  };

  const handleTemple = (e) => {
    const id = e.target.value,
      name = e.target.selectedOptions[0].innerHTML;
    setTempleId(id);
    setTempleName(name);
    setOTempleId(id);
    setOTempleName(name);
  };

  useEffect(() => {
    if (templeId) setTempleId(templeId);
  }, [templeId]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const handleEdit = () => {
    MySwal.fire({
      icon: "warning",
      title: <p>Are you sure you want to edit your changes?</p>,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditable(false);
      } else if (result.isDenied) {
        setEditable(true);
      }
    });
  };

  const handleOfferingType = (e) => {
    const val = e.target.value;
    setOfferingType(val);
    if (val === "other") {
      setShouldDisplay(false);
    } else {
      setShouldDisplay(true);
    }
  };

  const handleClearOtherForm = () => {
    setOFirstName("");
    setOLastName("");
    setOSpiritualName("");
    setOInitiationType("");
    setOInitiationYear("");
    setOIsInitiated("");
    setOCountryId("");
    setOCountryName("");
    setOStateId("");
    setOStateName("");
    setOCityId("");
    setOCityName("");
    setOTempleId("");
    setOTempleName("");
    setOOtherTempleName("");
    setOOtherFile("");
    setValidated(false);
    oFileRef.current.value = null;
    oCheckRef.current.checked = false;
  };

  const handleOtherSubmit = async (e) => {
    e.preventDefault();
    setOFirstName(removeSpaces(oFirstName));
    setOLastName(removeSpaces(oLastName));
    setOSpiritualName(removeSpaces(oSpiritualName));
    setOOtherTempleName(removeSpaces(oOtherTempleName));
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    files.append("file", oOtherFile);
    files.append("country", oCountryName);
    files.append("state", oStateName);
    files.append("city", oCityName);
    files.append("temple", oTempleId === "0" ? oOtherTempleName : oTempleName);
    files.append(
      "name",
      notNullNotUndefinedNotEmpty(oSpiritualName) ? oSpiritualName : oFirstName
    );

    const validatedUser = {
      oFirstName,
      oLastName,
      oCountryName,
      oStateName,
      oCityName,
      oTemple: oTempleId === "0" ? oOtherTempleName : oTempleId,
      oOtherFile,
      oGender,
      oSpiritualName: oIsInitiated ? oSpiritualName : null,
      oInitiationType: oIsInitiated ? oInitiationType : null,
      oInitiationYear: oIsInitiated ? oInitiationYear : null,
    };

    if (
      oIsInitiated
        ? Object.values(validatedUser).filter(Boolean).length === 11
        : Object.values(validatedUser).filter(Boolean).length === 8
    ) {
      let getURL;
      try {
        let fileData = await fetch(uploadOfferings, {
          method: "POST",
          body: files,
        });
        getURL = await fileData.json();
        console.log(getURL);
      } catch (error) {
        console.log("File uploading failed because of", error);
      }

      try {
        if (Object.keys(getURL).length > 0) {
          let obj = {
            userId: userID,
            offeringFile: getURL.url,
            initiationType: oInitiationType,
            initiationYear: oInitiationYear,
            firstName: oFirstName,
            lastName: oLastName,
            spiritualName: oSpiritualName,
            gender: oGender,
            country: oCountryName,
            state: oStateName,
            city: oCityName,
            templeName: oTempleId === "0" ? oOtherTempleName : oTempleName,
            templeId: +oTempleId || 0,
          };
          let addUser = await fetch(addOtherOfferings, options(obj));
          if (addUser.ok) {
            MySwal.fire("Congratualtion offering submitted successfully!");
            console.log({ validatedUser, email, userID, addUser });
            handleClearOtherForm();
          }
        }
      } catch (error) {
        console.log("Uploading other users offering failed because of", error);
      }
    } else {
      console.log("Please fill all the fields for", validatedUser);
    }
  };

  const handlePhoneChange = (val) => {
    if (val.toString().length < 6) {
      setPhoneError("Minimum length should be 6 digit!");
      setPhoneNumber("");
    }
  };

  return (
    <>
      {inMaintainenceMode ? (
        <div className="shadow-lg p-4 bg-white">
          <Row>
            <Col lg={6}>
              <h2>Hare Krishna,</h2>
              <p>Form is under maintainence mode</p>
              <p>
                We apologize for the inconvenience, but form submmission date
                has been extended to <strong>15-June-2023</strong>.
              </p>
              <p>
                We are performing essential updates and improvements to ensure a
                better browsing experience for our valued devotees. During this
                time, certain features or sections of the website may be
                temporarily unavailable.
              </p>
              <p>
                We appreciate your patience and understanding as we work
                diligently to complete the maintenance tasks as quickly as
                possible. We apologize for any inconvenience this may cause.
              </p>
              <p>
                If you have any urgent inquiries or require immediate
                assistance, please don't hesitate to contact us via email or
                phone from our contact us page.
              </p>
              <p>
                Thank you for your cooperation, and we look forward to serving
                you with an enhanced website shortly.
              </p>
            </Col>
            <Col lg={6}>
              <Image
                src={img}
                alt="maintainance"
                className="maintainance"
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          {/* <LoadingOverlay
          active={uploadingOffering}
          spinner={<GridLoader color="#3637d6" />}
          text="Uploading your offerings, Please wait....."
        > */}
          {shouldDisplay ? (
            <Form
              className="shadow-lg p-4 bg-white"
              validated={validated}
              noValidate
              onSubmit={handleSubmit}
            >
              {isUserExist && (
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button
                      size="lg"
                      onClick={handleEdit}
                    >
                      <i className="bi bi-pencil-square"> </i>Edit
                    </Button>
                  </Col>
                </Row>
              )}
              <Row className="my-5">
                <Col
                  sm={12}
                  md={4}
                  className="pb-3"
                >
                  <Form.Group controlId="validationEmail">
                    <Form.Label>
                      Email/ई-मेल<span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="user@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmail}
                      required
                      disabled={isDisable}
                    />
                    {emailMsg.length > 0 && <p className="msg">{emailMsg}</p>}
                  </Form.Group>
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className="pb-3"
                >
                  <Form.Label>
                    Mobile/मोबाइल<span className="msg">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={phoneNumber}
                    onKeyDown={blockInvalidChar}
                    onChange={(ev) => setPhoneNumber(ev.target.value)}
                    required
                    onWheel={(e) => e.currentTarget.blur()}
                    disabled={editable}
                  />
                  <Form.Control.Feedback type="invalid">
                    {phoneError}
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className="pb-3"
                >
                  <Form.Label>
                    Gender/लिंग<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    required
                    disabled={isDisable}
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select your gender
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    First Name/पहला नाम<span className="msg">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    pattern="[A-Za-z]{1,255}"
                    title="First name cannot contain number"
                    required
                    disabled={isDisable}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your first name
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Last Name/उपनाम<span className="msg">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    pattern="[A-Za-z]{1,255}"
                    title="Last name cannot contain number"
                    required
                    disabled={isDisable}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your last name
                  </Form.Control.Feedback>
                </Col>
                {/* {isUserExist && (
                  <Col>
                    <Form.Label>Offerings Type</Form.Label>
                    <Form.Select
                      value={offeringType}
                      onChange={handleOfferingType}
                    >
                      <option value="self">Self</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </Col>
                )} */}
              </Row>

              <Form.Check
                type="checkbox"
                value={isInitiated}
                id="Are you initiated?"
                ref={checkRef}
                label="Are you initiated?/क्या आप दीक्षित है?"
                onChange={(e) => setIsInitiated(e.target.checked)}
                disabled={editable}
              />

              {isInitiated && (
                <Row className="my-5">
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Spiritual Name/दीक्षित नाम<span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={spiritualName}
                      onChange={(e) => setSpiritualName(e.target.value)}
                      required={isInitiated}
                      disabled={editable}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please type your spiritual name
                    </Form.Control.Feedback>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Initiation/दीक्षा<span className="msg">*</span>
                    </Form.Label>
                    <Form.Select
                      value={initiationType}
                      required={isInitiated}
                      onChange={(e) => setInitiationType(e.target.value)}
                      disabled={editable}
                    >
                      <option value="">Select Initiation</option>
                      <option value="harinam">Harinam</option>
                      <option value="brahman">Brahman</option>
                      <option value="sanyas">Sanyas</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select your Initiation Type
                    </Form.Control.Feedback>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Year of Initiation/दीक्षा का वर्ष
                      <span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={initiationYear}
                      required={isInitiated}
                      onChange={(e) => setInitiationYear(e.target.value)}
                      disabled={editable}
                      pattern="[0-9]{4}"
                      onKeyDown={blockInvalidChar}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please type your initiation year
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              )}

              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Country/देश<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={countryId}
                    onChange={handleCountryChange}
                    disabled={editable}
                    required
                  >
                    <option value="">Select Country</option>
                    {notNullNotUndefinedNotEmpty(countries) &&
                      countries.length > 0 &&
                      countries.map((country) => (
                        <option
                          id={country.id}
                          name={country.countryName}
                          value={country.id}
                          key={country.id}
                        >
                          {capitalizeWords(country.countryName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select country
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    State/राज्य<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleStateChange}
                    value={stateId}
                    disabled={editable}
                    required
                  >
                    <option value="">Select State</option>
                    {notNullNotUndefinedNotEmpty(states) &&
                      states.length > 0 &&
                      states.map((val) => (
                        <option
                          key={val.id}
                          id={val.id}
                          name={val.stateName}
                          value={val.id}
                          className="states"
                        >
                          {capitalizeWords(val.stateName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select state
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    City/शहर<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={cityId}
                    onChange={handleCityChange}
                    disabled={editable}
                    required={
                      notNullNotUndefinedNotEmpty(cities) && cities.length > 0
                    }
                  >
                    <option value="">Select City</option>
                    {notNullNotUndefinedNotEmpty(cities) &&
                      cities.length > 0 &&
                      cities.map((city) => (
                        <option
                          key={city.id}
                          id={city.id}
                          name={city.cityName}
                          value={city.id}
                        >
                          {capitalizeWords(city.cityName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select city
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Temple/मन्दिर<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={templeId}
                    onChange={handleTemple}
                    required
                    disabled={editable}
                  >
                    <option value="">Select Temple</option>
                    {notNullNotUndefinedNotEmpty(temples) &&
                      temples.length > 0 &&
                      temples.map((temple) => (
                        <option
                          id={temple.id}
                          key={temple.id}
                          value={temple.id}
                          name={temple.templeName}
                        >
                          {temple.templeName}
                        </option>
                      ))}
                    <option
                      value="0"
                      id="0"
                    >
                      Other
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select temple
                  </Form.Control.Feedback>
                </Col>
              </Row>

              {templeId === "0" && (
                <Row className="my-5">
                  <Col
                    sm={12}
                    md={6}
                    className="pb-3"
                  >
                    <Form.Label>
                      Other Temple Name/अन्य मन्दिर नाम
                      <span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex:- ISKCON Juhu"
                      value={otherTempleName}
                      required={templeId === "0"}
                      onChange={(e) => setOtherTempleName(e.target.value)}
                      disabled={editable}
                      onKeyDown={handleOtherTemple}
                    />
                    {templeMsg.length > 0 && <p className="msg">{templeMsg}</p>}
                  </Col>
                  <Col />
                </Row>
              )}

              <div className="my-5">
                <Form.Control
                  type="file"
                  name="offerings"
                  ref={fileRef}
                  accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={handleFile}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please upload only document file
                </Form.Control.Feedback>
              </div>

              <Row className="my-5 mx-auto d-flex justify-content-between">
                <Col
                  className="d-flex justify-content-end send-offerings"
                  sm={12}
                  md={6}
                >
                  <Button
                    type="submit"
                    className="py-3 rounded-0"
                  >
                    Send Offerings/प्रार्थना भेजें
                  </Button>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="d-flex sample-offerings"
                >
                  <Button
                    className="py-3 rounded-0"
                    variant="success"
                    target="_blank"
                    href="https://gkgvyasapuja.blob.core.windows.net/offerings/Sample%20Offering%20English%20And%20Hindi.pdf"
                  >
                    Download sample Offering
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form
              className="shadow-lg p-4 bg-white"
              onSubmit={handleOtherSubmit}
              validated={validated}
              noValidate
            >
              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    First Name/पहला नाम<span className="msg">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={oFirstName}
                    onChange={(e) => setOFirstName(e.target.value)}
                    pattern="[A-Za-z]{1,255}"
                    title="First name cannot contain number"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your first name
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Last Name/उपनाम<span className="msg">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={oLastName}
                    onChange={(e) => setOLastName(e.target.value)}
                    pattern="[A-Za-z]{1,255}"
                    title="Last name cannot contain number"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your last name
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>Offerings Type</Form.Label>
                  <Form.Select
                    value={offeringType}
                    onChange={handleOfferingType}
                  >
                    <option value="self">Self</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Group>
                    <Form.Label>
                      Gender/लिंग<span className="msg">*</span>
                    </Form.Label>
                    <Form.Select
                      value={oGender}
                      onChange={(e) => setOGender(e.target.value)}
                      required
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select your gender
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-5">
                <Col>
                  <Form.Check
                    type="checkbox"
                    value={oIsInitiated}
                    ref={oCheckRef}
                    label="Are you initiated?/क्या आप दीक्षित है?"
                    onChange={(e) => setOIsInitiated(e.target.checked)}
                  />
                </Col>
              </Row>

              {oIsInitiated && (
                <Row className="my-5">
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Spiritual Name/दीक्षित नाम<span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={oSpiritualName}
                      onChange={(e) => setOSpiritualName(e.target.value)}
                      required={isInitiated}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please type your spiritual name
                    </Form.Control.Feedback>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Initiation/दीक्षा<span className="msg">*</span>
                    </Form.Label>
                    <Form.Select
                      value={oInitiationType}
                      required={isInitiated}
                      onChange={(e) => setOInitiationType(e.target.value)}
                    >
                      <option value="">Select Initiation</option>
                      <option value="harinam">Harinaaam</option>
                      <option value="brahman">Brahman</option>
                      <option value="sanyas">Sannnyas</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select your Initiation Type
                    </Form.Control.Feedback>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className="pb-3"
                  >
                    <Form.Label>
                      Year of Initiation/दीक्षा का वर्ष
                      <span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={oInitiationYear}
                      required={isInitiated}
                      onChange={(e) => setOInitiationYear(e.target.value)}
                      pattern="[0-9]{4}"
                      onKeyDown={blockInvalidChar}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please type your initiation year
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              )}

              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Country/देश<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={oCountryId}
                    onChange={handleCountryChange}
                    required
                  >
                    <option value="">Select Country</option>
                    {notNullNotUndefinedNotEmpty(countries) &&
                      countries.length > 0 &&
                      countries.map((country) => (
                        <option
                          id={country.id}
                          name={country.countryName}
                          value={country.id}
                          key={country.id}
                        >
                          {capitalizeWords(country.countryName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select country
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    State/राज्य<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={handleStateChange}
                    value={oStateId}
                    required
                  >
                    <option value="">Select State</option>
                    {notNullNotUndefinedNotEmpty(states) &&
                      states.length > 0 &&
                      states.map((val) => (
                        <option
                          key={val.id}
                          id={val.id}
                          name={val.stateName}
                          value={val.id}
                          className="states"
                        >
                          {capitalizeWords(val.stateName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select state
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="my-5">
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    City/शहर<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={oCityId}
                    onChange={handleCityChange}
                    required={
                      notNullNotUndefinedNotEmpty(cities) && cities.length > 0
                    }
                  >
                    <option value="">Select City</option>
                    {notNullNotUndefinedNotEmpty(cities) &&
                      cities.length > 0 &&
                      cities.map((city) => (
                        <option
                          key={city.id}
                          id={city.id}
                          name={city.cityName}
                          value={city.id}
                        >
                          {capitalizeWords(city.cityName)}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select city
                  </Form.Control.Feedback>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="pb-3"
                >
                  <Form.Label>
                    Temple/मन्दिर<span className="msg">*</span>
                  </Form.Label>
                  <Form.Select
                    value={oTempleId}
                    onChange={handleTemple}
                    required
                  >
                    <option value="">Select Temple</option>
                    {notNullNotUndefinedNotEmpty(temples) &&
                      temples.length > 0 &&
                      temples.map((temple) => (
                        <option
                          id={temple.id}
                          key={temple.id}
                          value={temple.id}
                          name={temple.templeName}
                        >
                          {temple.templeName}
                        </option>
                      ))}
                    <option
                      value="0"
                      id="0"
                    >
                      Other
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select temple
                  </Form.Control.Feedback>
                </Col>
              </Row>

              {oTempleId === "0" && (
                <Row className="my-5">
                  <Col
                    sm={12}
                    md={6}
                    className="pb-3"
                  >
                    <Form.Label>
                      Other Temple Name/अन्य मन्दिर नाम
                      <span className="msg">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex:- ISKCON Juhu"
                      value={oOtherTempleName}
                      required={oTempleId === "0"}
                      onChange={(e) => setOOtherTempleName(e.target.value)}
                      onKeyDown={handleOtherTemple}
                    />
                    {templeMsg.length > 0 && <p className="msg">{templeMsg}</p>}
                  </Col>
                  <Col />
                </Row>
              )}

              <div className="my-5">
                <Form.Control
                  type="file"
                  name="offerings"
                  ref={oFileRef}
                  accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={handleFile}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please upload only document file
                </Form.Control.Feedback>
              </div>
              <Row>
                <Col>
                  <Button type="submit">Submit Offering</Button>
                </Col>
              </Row>
            </Form>
          )}
          {/* </LoadingOverlay> */}
        </div>
      )}
    </>
  );
}
