import React from "react";
import {
  Card as Cards,
  Col,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./style.css";

const Card = ({year}) => {
  return (
    <>
      {year.map((item) => (
        item.status === 1 && <Col md={4} className="pb-4" key={item.id}>
          <Cards className="card cards-effect">
            <Cards.Img variant="top" src={item.thumbnail} alt={item.title} />
            <Cards.Body>
              <Cards.Title className="ebook-title h-2">
                <Nav.Link href="#" className="hovering txt-blue">
                  {item.title}
                </Nav.Link>
              </Cards.Title>
              <div className="d-flex justify-content-center py-4">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Download</Tooltip>}
                >
                  <Cards.Link
                    href={item.downloadUrl}
                    target="_blank"
                    className="border border-1 icon-hover rounded-3 text-secondary"
                  >
                    <i className="bi bi-download h-3"></i>
                  </Cards.Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Preview</Tooltip>}
                >
                  <Cards.Link
                    href={item.viewUrl}
                    target="_blank"
                    className="border border-1 icon-hover rounded-3  text-secondary"
                  >
                    <i className="bi bi-eye"></i>
                  </Cards.Link>
                </OverlayTrigger>
              </div>
            </Cards.Body>
          </Cards>
        </Col>
      ))}
    </>
  );
};

export default Card;
