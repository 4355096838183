import React from "react";
import { Container, Table } from "react-bootstrap";
import Banner from "../Components/Banner";
import "./Donate.css";

const Donate = () => {
  return (
    <>
      {/* <Banner txt="Donate" /> */}
      <Container>
        <h5 className="text-center pt-5 txt-blue">
          Devotees who are eager for contributing donation for Vyasa-puja book
          printing, kindly find the details below:
        </h5>
        <Table
          striped
          className="my-5 py-5"
        >
          <thead>
            <tr>
              <th
                colSpan={2}
                className="border-top py-3"
              >
                BANK DETAILS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Account Name:</td>
              <td>Karunasindhu</td>
            </tr>
            <tr>
              <td>Bank Name:</td>
              <td>IndianOverseasBank</td>
            </tr>
            <tr>
              <td>Branch:</td>
              <td>ISKCONJuhuBranch</td>
            </tr>
            <tr>
              <td>S.B.A/c.No.:</td>
              <td>124501000010370</td>
            </tr>
            <tr>
              <td>IFSCCode:</td>
              <td>IOBA0001245</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Donate;
