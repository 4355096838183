import React from "react";
/* import links from "../../../data.json";
import { Link } from "react-router-dom"; */
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  /* const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }; */
  return (
    <Container
      className="poster_color"
      fluid
    >
      <Container className="footer-wrapper">
        <Row>
          <Col
            lg={12}
            className="m-auto text-center"
          >
            <p className="footer__copyright align-middle my-4 m-auto text-white">
              &copy; <span>{new Date().getFullYear()}</span> GkgVyasaPuja All
              Rights Reserved
            </p>
          </Col>
          {/* <Col
            lg={8}
            className="px-0 m-auto"
          >
            <ul className="footer__links nav">
              {links.nav.map((item) => (
                <li
                  key={item.txt.toLowerCase()}
                  className="footer__lists nav-item"
                >
                  <Link
                    className="footer__anchor nav-link text-white"
                    to={item.path}
                  >
                    {item.txt}
                  </Link>
                </li>
              ))}
            </ul>
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
