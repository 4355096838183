// import Banner from "../Components/Banner";
import { Container, Row, Col } from "react-bootstrap";

export default function ContactUs() {
  return (
    <>
      {/* <Banner txt="Contact Us" /> */}
      <Container className="p-5 m-5">
        <Row>
          <Col>
            <h1>Contact Us</h1>
            <h3>Mobile Number</h3>
            <a href="tel:+91 9820196433">+91 9820196433</a>/
            <a href="tel:+91 9819780656">+91 9819780656</a>
            <h3>Email</h3>
            <a href="mailto:info@gkgvyasapuja.com">info@gkgvyasapuja.com</a>
          </Col>
          <div className="articleHide"></div>
        </Row>
      </Container>
    </>
  );
}
