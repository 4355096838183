import React, { useState } from "react";

function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to handle scroll events
  const handleScroll = () => {
    // If user has scrolled down 100 pixels, show the button, otherwise hide it
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when component mounts
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up event listener when component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <button
      onClick={scrollToTop}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: isVisible ? "block" : "none",
        backgroundColor: "#ff6a48",
        color: "#fff",
        border: "none",
        // borderRadius: "50%",
        padding: "15px",
        cursor: "pointer",
        zIndex: "9999",
      }}
    >
      <i className="bi bi-triangle-fill"></i>
    </button>
  );
}

export default BackToTopButton;
