import React from "react";
import data from "../../../data.json";
import Logo from "../../../assets/images/gkg_whiteLogo.png";
import {Container, Image, Navbar, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import './style.css';

const Header = () => {
  const social_media = [
    {
      path: "https://www.facebook.com/GopalKrishnaGoswami1/",
      icon: "bi bi-facebook",
      id: "facebook",
    },
    {
      path: "https://twitter.com/GKGMedia",
      icon: "bi bi-twitter",
      id: "twitter",
    },
    {
      path: "https://www.instagram.com/gopal_krishna_goswami/",
      icon: "bi bi-instagram",
      id: "instagram",
    },
    {
      path: "https://www.youtube.com/c/GopalKrishnaGoswamiOfficial",
      icon: "bi bi-youtube",
      id: "youtube",
    },
  ];
  return (
    <>
      <Container
        fluid
        className="poster_color border-bottom border-1 border-secondary"
      >
        <Container className="d-flex justify-content-end">
          {social_media.map((item) => (
            <Nav.Link
              href={item.path}
              key={item.id}
              target="_blank"
              id={item.id}
              className="text-white border-start hovering p-3"
            >
              <i className={`${item.icon}`}></i>
            </Nav.Link>
          ))}
        </Container>
      </Container>
      <Navbar className="poster_color" expand="lg" sticky="top">
        <Container className="header-navbar">
          <Navbar.Brand>
            <Nav.Link className="navbar-brand left" href="/">
              <Image className="logo" src={Logo} alt="logo" />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className="text-white"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="ms-auto header-navigation">
              {data.nav.slice(0, data.nav.length - 1).map((item) => (
                <Nav.Item className="header-nav-items" key={item.txt.toLowerCase()}>
                  <Link className="text-white hovering header-nav-links" to={item.path}>
                    {item.txt}
                  </Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
