export const initialFormVal = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  gender: "",
  isInitiated: false,
  initiatedName: "",
  initiatedType: "",
  initiatedYear: "",
  countryId: "",
  stateId: "",
  cityId: "",
  templeId: "",
  otherTempleName: "",
  offering: "",
};

export const genders = [
  { id: 0, label: "--Select--", value: "" },
  { id: 1, label: "Male", value: "male" },
  { id: 2, label: "Female", value: "female" },
  { id: 3, label: "Other", value: "other" },
];

export const initiationTypes = [
  { id: 0, label: "--Select--", value: "" },
  { id: 1, label: "Harinaam", value: "harinam" },
  { id: 2, label: "Brahman", value: "brahman" },
  { id: 3, label: "Sannyas", value: "sanyas" },
];

export const offeringTypes = [
  { id: 0, label: "--Select--", value: "" },
  { id: 1, label: "Self", value: "self" },
  { id: 2, label: "Other", value: "other" },
];
